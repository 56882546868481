<template>
  <div class="alone-possess">
    <NCard size="small" :border="false" :segmented="{ content: 'hard' }" :title="title">
      <NForm
        ref="formRef"
        label-placement="left"
        :label-width="120"
        require-mark-placement="left"
        :model="formValues"
        :rules="formRules"
      >
        <NFormItem label="标题：" path="Banner[title]" show-require-mark>
          <NInput
            style="width: 300px;"
            maxlength="50"
            placeholder="请输入标题"
            show-count
            clearable
            v-model:value="formValues['Banner[title]']"
          />
        </NFormItem>

         <NFormItem path="Banner[image_urls]" label="封面：" show-require-mark>
          <!-- 头像上传 -->
          <UPheadPortrait v-if="isOne" text="限上传PNG、JPG格式图片 设计最佳尺寸比例206:130，大小不超过2M" 
          @imagedata="imagedata" 
          size="2" 
          removeUrl="/backend/web/upload/delete" 
          :imaData="coverImg"/>

          <UPheadPortrait v-else text="限上传PNG、JPG格式图片 设计最佳尺寸比例206:130，大小不超过2M" 
          @imagedata="imagedata" 
          size="2" 
          removeUrl="/backend/web/upload/delete" 
          :imaData="formValues['Banner[image_urls]']"/>
        </NFormItem>

        <NFormItem label="排序：" path="Banner[sort]" >
          <n-input-number :show-button="false" :max="999" v-model:value="formValues['Banner[sort]']" style="width:300px" placeholder="请输入序号，数字越小，顺序越靠前" @update:value="NumberSort" />
        </NFormItem>
        
        <NFormItem label="详情：" path="Banner[title]" show-require-mark id="RichText" >
          <!-- 富文本 -->
          <RichText :msg="isPassValue" @todata="todata" :id="IDD"/>
        </NFormItem>
        
        <div class="banner-btns">
          <NSpace>
            <NButton @click="handleBack">返回</NButton>
            <NButton type="primary" @click="handleSave">确定</NButton>
          </NSpace>
        </div>

      </NForm>
    </NCard>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useMessage } from 'naive-ui';
  import { CloseCircleSharp as CashIcon } from '@vicons/ionicons5'
  
  import UPheadPortrait from '@/components/UPheadPortrait/index.vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { PostBannerUpdate, PostBannerCreate, GetBannerDetail } from '@/api/banner.js';  // 编辑提交 新增提交 详情
  import { resStatusEnum } from '@/enumerators/http.js';
  import RichText from '@/components/RichText/index.vue';
  
  const isPassValue = ref(false)  // 富文本是否开始传值
  const { SUCCESS } = resStatusEnum;
  const message = useMessage();
  const loading = ref(false);
  const isOne = ref(true);  // 数据是否初始化
  const route = useRoute();
  const router = useRouter();
  const teachingId = route.query.id;
  const IDD = ref(teachingId)

  const isEdit = !!teachingId;

  const formRef = ref(null);
  
  const title = ref("")
  
  title.value = teachingId?'广告编辑':'广告上传'

  // 添加系统事件

  // 编辑头像时原有文件列表
  const coverImg = []

  const formValues = ref({
    "Banner[id]":"",        //id
    "Banner[title]":"",     //标题
    "Banner[image_urls]":[{ name: "cc.png", url: "", status: "finished" }], //组件所用的图片路径 
    "Banner[image_url]":'',  //传给后端的图片路径
    "Banner[system]":[],    //系统id
    "Banner[sort]":1, //排序 
  });

  const formRules = {
    "Banner[title]": {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    "Banner[image_urls]": {
      key: 'coverImg',
      required: true,
      validator: (rule, value) => {
        let isUrl = ""
        if (formValues.value["Banner[image_urls]"][0].url == isUrl) {
          return new Error('请上传封面');
        } else {
          return true;
        }
      }
    }
  };

  const NumberSort = ()=>{
    let num = formValues.value['Banner[sort]']
    if(num === 0){
      message.info("排序值不能是0")
      formValues.value['Banner[sort]'] = 1
    }else if(num%1 != 0){
      message.info("排序值必须是整数")
      formValues.value['Banner[sort]'] = 1
    }
  }

  // 返回事件
  const handleBack = () => {
    window.location.href="/banner/index/index"; 
  };
  // 提交事件
  const handleSave = () => {
    loading.value = true;
    // 添加删除参数
    isPassValue.value = true
    setTimeout(function () {
      isPassValue.value = false
      submitFormValues()
    },3000)
  };

  // 编辑时 提交数据
  const submitFormValues = fileList => {
    if (isEdit) {
      setTimeout(function(){
        loading.value = true;
        PostBannerUpdate(formValues.value).then(res => {
          if (res.code === SUCCESS) {
            loading.value = false;
            message.success(res.msg);
            handleBack()
          }else{
            isPassValue.value = false
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
      },2000)
    }else{
      // 新增时提交
      setTimeout(function () {
        PostBannerCreate(formValues.value).then(res => {
        if (res.code === SUCCESS) {
          loading.value = false;
          message.success(res.msg);
          handleBack()
        }else{
          if(res.msg=="封面不能为空。"){
            formValues.value["Banner[image_urls]"] = []
          }
          isPassValue.value = false
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
        isPassValue.value = false
      });
      },2000)
    }
  };

  // 编辑时初始化
  if (isEdit) {
    loading.value = true;
    GetBannerDetail({
      'Banner[id]': teachingId
    }).then(res => {
      loading.value = false;
      formValues.value["Banner[id]"] = res.data.id
      formValues.value["Banner[title]"] = res.data.title
      formValues.value["Banner[sort]"] = Number(res.data.sort)
      formValues.value["Banner[image_urls]"] = [{ name: "cc.png", url:res.data.image_url, status: "finished" }], // 图标url数组
      formValues.value["Banner[image_url]"] = res.data.image_url                    // 图标url
      isOne.value = false;
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }

  // 监听子组件传来的值
  // 提交事件
  const todata = (data)=>{
    console.log("监听子组件传来的值",isPassValue.value);
    // if(isPassValue.value==false){
      formValues.value["Banner[introduce]"] = data          // 富文本数据 
      // 获取所有图片的src
      var imgList = [];
      data.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
        imgList.push(capture);
      });
      data.replace(/<iframe [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
        imgList.push(capture);
      });
      console.log("监听子组件传来的值",imgList)
      formValues.value["Banner[upload_ids]"] = imgList      // 所有图片地址
    // }
  }
  
  // 父子组件传值 封面
  const imagedata = (params)=> {
    if(params.length==0){
      formValues.value["Banner[image_urls]"] = []
      formValues.value["Banner[image_url]"] = ''
    }else{
      console.log('父子组件传值999',params[0].url);
      formValues.value["Banner[image_urls]"] = params
      formValues.value["Banner[image_url]"] = params[0].url
    }
  }

</script>
<style scoped>
.banner-btns {
  margin-top:70px;
  margin-left: 120px;
}
.banner-btns .n-space button{
  width:120px;
}
#RichText .n-form-item-blank{
  display: block !important
}
/* 隐藏视频上传按钮 */

</style>
<style lang="less">
.alone-possess .n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger{
  width: 177px !important;
  height:100px;
  position: relative;
  background-size: 100% 100% !important;
}
.alone-possess .title-up-text {
  left: 200px !important;
}
.alone-possess .n-upload-file-list .n-upload-file.n-upload-file--image-card-type{
  width: 177px !important;
}
.alone-possess .title-up-text{
  width: 144px !important;
}
.alone-possess .n-card-header .n-card-header__main{
  font-weight: 700;
}
</style>